require('../../node_modules/owl.carousel/dist/owl.carousel.min.js');
import 'magnific-popup';


jQuery( document ).ready(function($) {
    var videos = $('.owl-carousel').owlCarousel({
        loop:true,
        items: 1,
        margin: 15,
        dots: false,
        nav: false,
        stagePadding: 25,
        smartSpeed: 500,
        responsive:{
            800:{
                stagePadding: 50,
                margin: 0,
            },
            1100:{
                stagePadding: 200,
                margin: 0,
            }
        }
    });

    $('.owl-carousel .next').on('click', function(e){
        e.preventDefault();
        videos.trigger('next.owl.carousel');
    });

    $('.owl-carousel .prev').on('click', function(e){
        e.preventDefault();
        videos.trigger('prev.owl.carousel');
    });

    $('.newsletter').on('submit', function(e) {
        e.preventDefault();
        const DATA = $(this).serialize();
    
        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            dataType: 'json',
            data: DATA,
            xhrFields: {
                withCredentials: false,
            },
            success: function (data) {
                $('.inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
            },
            error: function (err) {
                console.log(err);
            }
        });
    });
});